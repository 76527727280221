.cesium-infoBox{
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
    border: none !important;
    
}


.cesium-infoBox-title, .cesium-infoBox-camera{
    display: none !important;
}

.cesium-infoBox-iframe{
    height:420px !important;
    width: 255px !important;
}

.cesium-infoBox{
    width: fit-content !important;
}

/* 파노라마 뷰어 스타일 */
.psv-loader-text{
    color: black !important;
    margin-right: 5px;
}

.psv-loader-container{
    left:-18px !important;
}

.btnStyle{
    font-size: small;
    background-color:#0a0a23;
    color: #fff;
    border: 1px solid white;
    padding: 9px;
    border-radius:8px;
    margin-top: 7px;
    margin-left: 7px;
    cursor: pointer;
}

.btnStyle:hover {
    color:yellow;
    transition: 0.7s;
}

.popupStyle{
    width: 35vw;                                                                                                                                                                                                                                                                                                                                                                             
    padding: 10px;
    font-size: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    top: -7px;
    background: rgba(0, 0, 0, 0.75);
    right: 30vw;
    position: absolute;
}
@media (max-width: 768px) {
    .popupStyle {
      width: 50vw; /* 모바일 화면에서 넓이를 50vh로 설정 */
      right: 20vw;
    }
  }